<template>
	<v-col cols="12" sm="12" md="12" lg="12" xl="12" class="d-flex flex-column align-self-stretch pa-0">
		<v-card flat tile :loading="intervalsLoading" class="fill-height">
			<v-row class="pa-11 pb-0 justify-start align-center">
				<h3>Interval Messages</h3>
				<NewInterval @NewIntervalAdded="updateIntervals"/>
			</v-row>
			
			<v-simple-table class="pa-4">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Name</th>
							<th class="text-left">Message</th>
							<th class="text-left">Interval</th>
							<th class="text-left"></th>
						</tr>
					</thead>
					
					<tbody>
						<EditIntervals
							v-for="(interval) in repeating_messages" 
							:key="interval.id"
							:interval="interval" 
							@IntervalDeleted="fetchIntervals"
							@IntervalEdited="fetchIntervals"
						/>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>
	</v-col>
</template>

<script>
import EditIntervals from './EditIntervals.vue';
import NewInterval from './NewInterval.vue';

export default {
	components: {
		NewInterval,
		EditIntervals
	},

	data() {
		return {
			intervalsLoading: true,
			repeating_messages: [],
		}
	},

	async mounted() {
		await this.fetchIntervals();
	},

	methods: {
		async updateIntervals() {
			await this.fetchIntervals();
		},

		fetchIntervals: async function() {
			try {
				const request = await fetch(this.$store.state.base_url + '/intervals', {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				const res = await request.json();

				if (request.ok && request.status === 200) {
					if (res.intervals) {
						this.repeating_messages = res.intervals;
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message
				});
			}
			finally {
				this.intervalsLoading = false;
			}
		},
	},
}
</script>